.home-img {
    max-width: 100%;
}
.image {
    display: none;
}
.imgWrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
}
.imgWrapper img {
    height: 100%;
    width: 100%;
}
@keyframes blender {
    0% {opacity: 0;transform: scale(1);}
    30% {opacity: 1; transform: scale(1.02);}
    70% { opacity: 1; transform: scale(1.04);}
    100% {opacity: 0;transform: scale(1.06);}
}
.imageActive {
    float: left;
    width: 150%;
    height: 100%;
    object-fit: cover;
    box-sizing: border-box;
    opacity: 0;
    animation: blender 3s linear 10ms;
}