.trainingdetailpage-maincontainer {
    width: 100%;
}
.trainingdetailpage-imagecontainer {
    width: 100%;
    height: 300px;
    background: url('../../assets/trainingDetailImage.jpg');
    background-size: 100% auto;
    background-position: center;
}
.trainingdetailpage-imagecontainer div {
    width: 100%;
    height: 100%;
    font-size: 26px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
}
.trainingdetailpage-detailmaincontainer {
    width: 100%;
    margin: 2% 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.trainingdetailpage-detailsubcontainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.trainingdetailpage-detailsubcontainer h2 {
    color: #2A2A2A;
    font-size: 36px;
}
.trainingdetailpage-detailsubcontainer p {
    color: #727272;
}
.trainingdetailpage-detailsubcontainer li {
    margin-left: 15px;
}

@media screen and (max-width: 480px) {
    .trainingdetailpage-imagecontainer {
        height: 180px !important;
    }
    .trainingdetailpage-imagecontainer div {
        font-size: 22px;
    }
}