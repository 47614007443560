.footer-maincontainer {
    width: 100%;
    display: flex;
    color: white;
    justify-content: center;
    background-color: #173787;
}
.footer-subcontainer {
    width: 90%;
}
.footer-separator {
    height: 1px;
    background-color: #4771DA;
    width: 100%;
    margin: 10px 0;
}
.footer-bottomcontainer {
    display: flex;
    padding: 30px 0;
    justify-content: flex-end;
    align-items: center;
    font-weight: 600;
}
.footer-topcontainer {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
}
.footer-topcontainer-logocontainer {
    width: 20%;
}
.footer-topcontainer-logocontainer h1 { 
    color: white;
    margin-top: 10px;
}
.footer-topcontainer-logocontainer div {
    height: 90px !important;
    width: 90px !important;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.footer-topcontainer-logocontainer img {
    height: 85%;
    width: 85%;
}
.footer-topcontainer h4 {
    font-size: 22px;
    margin: 15px 0px;
}
.footer-topcontainer hr {
    width: 40px;
    height: 1px;
    outline: none;
    border: none;
    background-color: #FF6637;
}
.footer-topcontainer p {
    margin: 15px 0px;
    transition: 0.4s;
}
.footer-topcontainer p:hover {
    color: #FF6637;
}
.footer-topcontainer-socialmedialogos {
    display: flex;
}
.footer-topcontainer-socialmedialogos div {
    padding: 5px;
    height: 25px;
    margin-top: 10px;
    width: 25px !important;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #FF6637;
    transition: 0.2s;
}
.footer-topcontainer-socialmedialogos div svg {
    fill: white;
}
.footer-topcontainer-socialmedialogos div:hover {
    transform: scale(1.09);
}

@media screen and (max-width: 480px) {
    .footer-topcontainer {
        flex-direction: column;
        gap: 20px;
    }
    .footer-topcontainer-logocontainer {
        display: flex;
        flex-direction: column;
    }
    .footer-topcontainer div {
        width: 100% !important;
    }
    .footer-topcontainer-logocontainer div {
        width: 75px !important;
        height: 75px !important;
    }
    .footer-topcontainer-socialmedialogos div {
        width: 25px !important;
        height: 25px !important;
    } 
    .footer-topcontainer-logocontainer h1 {
        font-size: 25px;
        text-align: center;
        margin-top: 3% !important;
    }
}

@media screen and (max-width: 820px) {
    .footer-topcontainer {
        flex-wrap: wrap;
    }
    .footer-topcontainer-logocontainer {
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 4%;
    }
    .footer-topcontainer-logocontainer h1 {
        margin: 0;
        margin-left: 2%;
    }
    .footer-topcontainer div {
        width: 30%;
    }
}