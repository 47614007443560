.navbar-maincontainer {
    background-color: #173787;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.navbar-subcontainer {
    width: 90%;
}
.navbar-topcontainer {
    margin: 5px 0px;
    padding: 5px 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.activeClass {
    color: #FF6637 !important;
}
.navbar-topcontainer p {
    display: flex;
    margin-right: 30px;
    justify-content: center;
}
.navbar-topcontainer div {
    display: flex;
}
.navbar-topcontainer p svg {
    fill: #FF6637;
    margin-right: 5px;
}
.navbar-separator {
    height: 1px;
    background-color: #4771DA;
    width: 100%;
    margin: 10px 0;
}
.navbar-bottomcontainer {
    margin: 10px 0px;
    display: flex;
    padding: 5px 0;
    position: relative;
    justify-content: space-between;
    align-items: center;
}
.navbar-topcontainer-socialmedialogos div {
    padding: 5px;
    border-radius: 50%;
    margin-left: 10px;
    background-color: #FF6637;
    transition: 0.2s;
}
.navbar-topcontainer-socialmedialogos div svg {
    fill: white;
}
.navbar-topcontainer-socialmedialogos div:hover {
    transform: scale(1.09);
}
.navbar-bottomcontainer-logocontainer {
    display: flex;
    align-items: center;
}
.navbar-bottomcontainer-logocontainer h1 {
    color: white;
    margin-left: 20px;
    font-size: 25px;
}
.navbar-bottomcontainer-logocontainer div {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.navbar-bottomcontainer-logocontainer img {
    height: 85%;
    width: 85%;
}
.navbar-bottomcontainer a {
    color: white;
    text-decoration: none;
    margin-left: 20px;
    font-size: 17px;
    font-weight: 500;
    transition: 0.4s;
}
.navbar-bottomcontainer a:hover {
    color: #FF6637;
}
.navbar-hamburger {
    display: none;
}

@media screen and (max-width: 480px) {
    .navbar-topcontainer, .navbar-separator {
        display: none;
    }
    .navbar-hamburger {
        display: block;
    }
    .navbar-bottomcontainer-logocontainer div {
        width: 50px !important;
        height: 50px !important;
    }
    .navbar-bottomcontainer-navlinks {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 135%;
        padding: 2%;
        box-sizing: border-box;
        width: 100%;
        border-radius: 8px;
        background-color: #173787;
        left: 0;
        animation: navlinkanimation 0.6s;
        z-index: 10;
    }
    .navbar-bottomcontainer-navlinks a {
        margin: 3%;
    }
}

@keyframes navlinkanimation {
    0% {transform: translateY(-50px); opacity: 0;}
    100% {transform: translateY(0px); opacity: 1;}
}

@media screen and (max-width: 820px) {
    .navbar-bottomcontainer-logocontainer div {
        height: 60px;
        width: 60px;
    }
    .navbar-bottomcontainer-logocontainer h1 {
        font-size: 16px !important;
        text-align: center;
    }
    .navbar-topcontainer p {
        font-size: 14px;
    }
}