.homepage-introimage-container {
    width: 100%;
    height: 80vh;
    position: relative;
    background-color: black;
}
.homepage-introcontainer {
    height: fit-content;
    width: 100%;
}
.homepage-introcontainer-blocks {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    margin-top: -5%;
}
.homepage-introcontainer-blocks-subcontainer {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.homepage-introcontainer-block {
    width: 30%;
    padding: 40px 30px;
    box-shadow: 0px 0px 25px -10px rgb(0 0 0 / 30%);
    box-sizing: border-box;
    transition: 0.4s;
    background-color: white;
    z-index: 2;
    border-radius: 8px;
}
.homepage-introcontainer-block img {
    width: 15%;
}
.homepage-introcontainer-block h2 {
    transition: 0.4s;
    color: #173787;
    margin: 10px 0;
}
.homepage-introcontainer-block p {
    color: #727272;
    transition: 0.4s;
}
.homepage-introcontainer-block:hover {
    background-color: #173787;
}
.homepage-introcontainer-block:hover h2,
.homepage-introcontainer-block:hover p {
    color: white;
}
.homepage-introimages {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
}
.homepage-introimage-content {
    z-index: 1;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.homepage-introimage-subcontent {
    width: 90%;
    height: 60%;
    margin-top: -5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
}
.homepage-introimage-subcontent h5,
.homepage-aboutus-contentcontainer h5,
.homepage-training-subcontainer h5 {
    letter-spacing: 5px;
    font-size: 16px;
    width: 60%;
}
.homepage-aboutus-contentcontainer h5,
.homepage-training-subcontainer h5 {
    color: #FF6637;
}
.homepage-training-subcontainer h5 {
    width: fit-content;
}
.homepage-introimage-subcontent h1 {
    width: 50%;
    font-size: 64px;
    font-weight: 700;
    line-height: 70px;
}
.homepage-aboutus-contentcontainer h1,
.homepage-training-subcontainer h2 {
    font-weight: 700;
    line-height: 50px;
    font-size: 40px;
    color: #2A2A2A;
}
.homepage-introimage-subcontent p {
    width: 50%;
}
.homepage-introimage-subcontent a {
    padding: 15px 30px;
    border-radius: 5px;
    background-color: #FF6637;
    text-decoration: none;
    letter-spacing: 1px;
    color: white;
    text-align: center;
    width: 12%;
    transition: 0.4s;
}
.homepage-introimage-subcontent a:hover {
    background-color: #173787;
    transform: scale(0.9);
}
.homepage-aboutus-maincontainer {
    width: 100%;
    margin: 5% 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.homepage-aboutus-subcontainer {
    width: 90%;
    display: flex;
}
.homepage-aboutus-imagecontainer {
    width: 40%;
    position: relative;
}
.homepage-aboutus-contentcontainer {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.homepage-aboutus-imageone {
    width: 70%;
    position: absolute;
    top: 0;
    left: 0;
}
.homepage-aboutus-imagetwo {
    width: 70%;
    position: absolute;
    bottom: 0;
    right: 15%;
}
.homepage-aboutus-imageone img,
.homepage-aboutus-imagetwo img {
    width: 100%;
}
.homepage-aboutus-contentcontainer p,
.homepage-training-subcontainer p {
    color: #727272;
    word-wrap: break-word;
}
.homepage-aboutus-contentcontainer hr {
    background-color: #CBCBCB;
    outline: none;
    border: none;
    height: 1px;
    margin: 4% 0;
}
.homepage-aboutus-content-boxcontainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.homepage-aboutus-content-box {
    width: 30%;
}
.homepage-aboutus-content-box img {
    width: 25%;
}
.homepage-aboutus-content-box h6 {
    font-size: 16px;
    color: #173787;
    font-weight: 600;
}
.homepage-aboutus-content-box h1 {
    margin: 5% 0;
}
.homepage-aboutus-content-box span {
    color: #FF6637;
}
.homepage-missioncontainer {
    width: 100%;
    margin: 5% 0;
    color: white;
    display: flex;
}
.homepage-mission-imagecontainer,
.homepage-mission-contentcontainer {
    width: 50%;
}
.homepage-mission-imagecontainer {
    background: url("../../assets/homepage-missionimage.jpg");
    background-size: 100% 100%;
    background-position: center;
}
.homepage-mission-missioncontainer {
    width: 100%;
    box-sizing: border-box;
    padding: 8% 5%;
    background-color: #173787;
}
.homepage-mission-visioncontainer {
    width: 100%;
    box-sizing: border-box;
    padding: 8% 5%;
    background-color: #FF6637;
}
.homepage-mission-contentcontainer h1 {
    font-size: 40px;
    margin-bottom: 4%;
}
.homepage-trainingmaincontainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.homepage-training-subcontainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.homepage-training-subcontainer h2 {
    margin: 1% 0;
    font-size: 600;
    width: 50%;
    text-align: center;
}
.homepage-training-cardcontainer {
    width: 100%;
    margin-top: 2%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media screen and (max-width: 480px) {
    .homepage-aboutus-contentcontainer h1, .homepage-training-subcontainer h2, .homepage-introimage-subcontent h1 {
        font-size: 28px !important;
        line-height: 40px;
    }
    .homepage-introimage-subcontent {
        align-items: center;
        text-align: center;
    }
    .homepage-introimage-subcontent h5, .homepage-aboutus-contentcontainer h5, .homepage-training-subcontainer h5 {
        width: 100%;
    }
    .homepage-introimage-subcontent a {
        width: 50% !important;
    }
    .homepage-aboutus-imagecontainer {
        height: 45vh !important;
    }
    .homepage-aboutus-contentcontainer h5, .homepage-training-subcontainer h5 {
        text-align: center;
    }
    .homepage-training-subcontainer h2 {
        width: 100% !important;
    }
    .homepage-aboutus-contentcontainer, .homepage-training-subcontainer {
        text-align: center;
    }
    .homepage-aboutus-contentcontainer p, .homepage-training-subcontainer p {
        word-break: break-all;
    }
}

@media screen and (max-width: 820px) {
    .homepage-introimage-subcontent h1 {
        width: 100%;
        font-size: 48px;
    }
    .homepage-introimage-subcontent p {
        width: 100%;
    }
    .homepage-introimage-subcontent a {
        padding: 18px;
        width: 30%;
        font-size: 16px;
    }
    .homepage-introcontainer-blocks-subcontainer {
        flex-direction: column;
    }
    .homepage-introcontainer-block {
        width: 100%;
        margin: 2% 0;
    }
    .homepage-introcontainer-blocks {
        margin-top: -14%;
    }
    .homepage-aboutus-subcontainer {
        flex-direction: column;
    }
    .homepage-aboutus-imagecontainer {
        width: 100%;
        height: 70vh;
    }
    .homepage-aboutus-imagetwo {
        right: 0;
    }
    .homepage-aboutus-contentcontainer {
        width: 100%;
        margin: 5% 0;
    }
    .homepage-aboutus-contentcontainer h1, .homepage-training-subcontainer h2 {
        font-size: 32px;
    }
    .homepage-training-subcontainer h2 {
        line-height: 35px;
        width: 70%;
    }
    .homepage-training-subcontainer {
        gap: 10px;
    }
    .homepage-mission-imagecontainer {
        display: none;
    }
    .homepage-mission-contentcontainer {
        width: 100%;
    }
    .homepage-mission-missioncontainer , .homepage-mission-visioncontainer {
        text-align: center;
    }
}