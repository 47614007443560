.contactpage-maincontainer {
    width: 100%;
}
.contactpage-imagecontainer {
    width: 100%;
    height: 350px;
    background: url('../../assets/contactusImage.jpg');
    background-size: 100% auto;
    background-position: center;
}
.contactpage-imagecontainer div {
    width: 100%;
    height: 100%;
    font-size: 26px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
}
.contactpage-form-maincontainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4% 0;
}
.contactpage-form-subcontainer {
    width: 90%;
    display: flex;
}
.contactform-container,
.contactform-detailcontainer {
    width: 50%;
}
.contactform-detailcontainer {
    display: flex;
    justify-content: center;
}
.contactpage-content-box h6 {
    font-size: 22px;
    margin: 1% 0;
    color: #173787;
}
.contactform-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.contactform-form {
    width: 90%;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 25px -10px rgb(0 0 0 / 30%);
    background: linear-gradient(to top left, #e6e6e6, white);
    background-color: beige;
}
.contactform-form h1 {
    font-size: 40px;
    margin: 5% 0;
    line-height: 50px;
    color: #2A2A2A;
}
.contactform-flexcontainer {
    display: flex;
    width: 85%;
    justify-content: space-between;
}
.contactform-inputcontainer {
    width: 48%;
    margin: 2% 0;
}
.contactform-inputcontainer label {
    width: 100%;
    margin-bottom: 1%;
    display: flex;
    color: #FF6637;
    font-size: 16px;
}
.contactform-inputcontainer input,
.contactform-inputcontainer textarea {
    width: 100%;
    padding: 12px;
    outline: none;
    border: 1px solid #173787;
    color: #727272;
    box-sizing: border-box;
}
.contactform-textarea {
    width: 100%;
}
.contactform-inputcontainer textarea {
    height: 150px;
}
.contactform-form button {
    width: 85%;
    padding: 15px 30px;
    font-size: 16px;
    margin-bottom: 5%;
    box-sizing: border-box;
    letter-spacing: 1px;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 50%);
    border-radius: 5px;
    color: white;
    font-weight: 500;
    outline: none;
    border: none;
    transition: 0.4s;
    background-color: #FF6637;
}
.contactform-form button:hover {
    background-color: #173787;
    cursor: pointer;
}
.contactpage-content-box div {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

@media screen and (max-width: 480px) {
    .contactpage-imagecontainer {
        height: 180px !important;
    }
    .contactpage-imagecontainer div {
        font-size: 22px;
    }
}

@media screen and (max-width: 820px) {
    .contactpage-form-subcontainer {
        flex-direction: column;
    }
    .contactform-form {
        width: 100%;
    }
    .contactform-container {
        width: 100%;
    }
    .contactform-detailcontainer {
        width: 100%;
    }
    .contactpage-imagecontainer {
        height: 200px;
    }
}