.productpage-maincontainer {
    width: 100%;
}
.productpage-imagecontainer {
    width: 100%;
    height: 300px;
    background: url('../../assets/ourteamimage.jpg');
    background-size: 100% auto;
    background-position: center;
}
.productpage-imagecontainer div {
    width: 100%;
    height: 100%;
    font-size: 26px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
}
.productpage-cardcontainer {
    display: flex;
    margin: 3% 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.productpage-cardcontainer h5 {
    letter-spacing: 5px;
    font-size: 16px;
    /* width: 60%; */
    color: #FF6637;
}
.productpage-cards-maincontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.productpage-cards-subcontainer {
    display: flex;
    width: 90%;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media screen and (max-width: 480px) {
    .productpage-imagecontainer {
        height: 180px !important;
    }
    .productpage-imagecontainer div {
        font-size: 22px;
    }
}