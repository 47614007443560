.productcard-container {
    width: 22%;
    margin: 1%;
    height: 200px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 0px 25px -10px rgb(0 0 0 / 30%);
}
.productcard-container a {
    text-decoration: none;
}
.productcard-description {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.6s;
    box-sizing: border-box;
    padding: 12px;
}
.productcard-description p {
    color: #173787;
    transition: 0.6s;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
}
.productcard-description:hover {
    background-color: #173787;
}

.productcard-description:hover p {
    color: white;
}

@media screen and (max-width: 480px) {
    .productcard-container {
        width: 100% !important;
        margin: 3% 0 !important;
    }
    .productcard-description p {
        font-size: 18px;
    }
}

@media screen and (max-width: 820px) {
    .productcard-container {
        width: 45%;
     }
}