.disclaimer-maincontainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.disclaimer-container {
    width: 88%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.disclaimer-container h1 {
    margin-bottom: 3%;
}
.disclaimer-container p {
    text-align: justify;
    color: #727272;
    margin-bottom: 3%;
}