.servicecard-container {
    width: 30%;
    margin: 1%;
    overflow: hidden;
    position: relative;
}
.servicecard-container img {
    width: 100%;
    height: 100%;
}
.servicecard-description {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 98%;
    left: 0;
    display: flex;
    opacity: 0;
    justify-content: center;
    align-items: center;
    transition: 0.6s;
    background-color: #173787;
}
.servicecard-description p {
    font-size: 24px;
    color: white !important;
} 
.servicecard-container:hover .servicecard-description {
    top: 0;
    opacity: 1;
    cursor: pointer;
}
@media screen and (max-width: 480px) {
    .servicecard-container {
        width: 100% !important;
        margin: 3% 0 !important;
    }
}

@media screen and (max-width: 820px) {
    .servicecard-container {
        width: 45%;
     }
}
