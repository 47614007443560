body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

.quote-text {
  font-size: 19px;
  font-style: italic;
  color: black !important;
  font-weight: 500;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #FF6637;
  border-radius: 20px;
}